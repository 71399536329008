<template>
  <v-card>
    <v-card-title>Změnit stav</v-card-title>
    <v-card-text class="pb-0">
      <v-select
        :items="approvalProcessStates"
        label="Stav"
        class="rounded-0"
        outlined
        v-model="form.approvalProcessState"
      >
      </v-select>
      <v-textarea
        v-if="form.approvalProcessState === 1 || form.approvalProcessState === 2"
        v-model="form.reasonOfRejection"
        label="Poznámka"
      ></v-textarea>
    </v-card-text>
    <v-card-actions class="d-flex justify-end pt-0">
      <v-btn
        color="primary"
        class="rounded-0"
        :loading="runningRequest"
        :disabled="runningRequest"
        @click="save"
      >
        Uložit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ApprovalProcessStateForm',
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      runningRequest: false,
      form: {
        approvalProcessState: null,
        reasonOfRejection: null
      },
      approvalProcessStates: [
        {
          text: 'Schválená',
          value: 3
        },
        {
          text: 'Neschválená',
          value: 2
        },
        {
          text: 'Čekající',
          value: 1
        }
      ]
    }
  },
  methods: {
    save () {
      this.runningRequest = true
      this.$http.patch(`/admin/properties/${this.property.id}/state`, this.form)
        .then(() => {
          this.$emit('edit-success')
        })
        .catch(() => {
          this.$emit('edit-failure')
        })
    }
  },
  created () {
    this.form.approvalProcessState = this.property.approvalProcessState
    this.form.reasonOfRejection = this.property.reasonOfRejection
  },
  watch: {
    'form.approvalProcessState': function (newVal) {
      if (newVal === 3) {
        this.form.reasonOfRejection = null
      }
    }
  }
}
</script>

<style scoped>

</style>
